import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators'
import Picture from '../../../components/atoms/Picture';
import TextRotateAnimation from '../../../components/atoms/TextRotateAnimation';
import Title from '../../../components/atoms/Title';
import Typography from '../../../components/atoms/Typography';
import ButtonMUI from '../../../components/atoms/Button';
import { canUseDOM } from '../../../utils/canUseDOM';
import { get, isEmpty } from 'lodash';
import RichText from '../../../components/atoms/RichText';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import {
  pushAnaylyticsData,
} from '../../../../../src/utils/analytics';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';

const BannerSupergraphic = (props) => {
  const isFieldsEmpty = checkEmptyObject(props.fields);
  const {
    CTA,
    SEOEnglishCTA,
    bgImage,
    bgImageMobile,
    supergraphicImage,
    supergraphicImageMobile,
    title,
    description,
    segmentText,
    smallTitle,
    objectImage,
  } = props.fields;
  const classes = useStyles();

  const [isMobile, setIsMobile] = useState('');
  const pageName = getPageName(props);
  const componentName = getComponentName(props);

  useEffect(() => {
    if (canUseDOM) {
      const setIsMobileFunc = () => {
        setIsMobile(window.innerWidth < 1024 ? 'mobile' : 'desktop');
      }

      setIsMobileFunc();

      window.addEventListener('resize', () => {
        setIsMobileFunc()
      });
    }
  }, [])

  const analyticsEventCTA = () => {
    const seoCtaText = get(SEOEnglishCTA, 'value', '');
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoCtaText,
      },
    };
    pushAnaylyticsData(obj);
  }

  const sImage = isMobile == 'mobile' ? supergraphicImageMobile : supergraphicImage;

  return (
    isFieldsEmpty ? <></> :
      <div className={classes.wrapper} data-locator={COMPONENT_NAME}>
        {bgImage && !isEmpty(get(bgImage, 'value.src')) && <div className={classes.bgImage}>
          <Picture
            media={{
              image: isMobile === 'mobile' ? bgImageMobile : bgImage,
              mobileImage: bgImageMobile,
              disableLazyLoad: false,
              preferredSize: isMobile === 'mobile' ? '2x4' : '5x4',
              aspectRatio: { mobile: '2x4', desktop: '5x4' },
              fallbackBackgroundNeeded: false,
            }}
            isParalax={true}
            paralaxScrollAmount={-16.6}
            className="bannerBGImage"
          />
        </div>}

        {sImage && !isEmpty(get(sImage, 'value.src')) && <div className={classes.sgImage}>
          <Picture
            media={{
              image: sImage,
              mobileImage: sImage,
              disableLazyLoad: false,
              preferredSize: '350x80',
              aspectRatio: { mobile: '350x80', desktop: '350x80' },
              fallbackBackgroundNeeded: false,
            }}
            isParalax={isMobile != 'mobile'}
            paralaxScrollAmount={-10}
            className="bannerSGImage"
          />
        </div>}
        {isMobile != 'mobile' && objectImage && !isEmpty(get(objectImage, 'value.src')) && <div className={classes.ogImage}>
          <Picture
            media={{
              image: objectImage,
              mobileImage: objectImage,
              disableLazyLoad: false,
              preferredSize: '350x80',
              aspectRatio: { mobile: '350x80', desktop: '350x80' },
              fallbackBackgroundNeeded: false,
            }}
            isParalax={true}
            paralaxScrollAmount={-20}
            className="bannerOGImage"
          />
        </div>}
        <div className={classes.bannerTextArea}>
          <div>
            {segmentText && segmentText.value && (
              <TextRotateAnimation
                condition={true}
                animateSettings={{
                  willAnimate: true,
                  selfTrigger: true,
                  duration: '250ms',
                }}
              >
                <Title text={segmentText} className={classes.uppercase} />
              </TextRotateAnimation>
            )}
            {title && title.value && (
              <Typography
                variant="h2"
                component="h2"
                gutterBottom={false}
                className={classes.heading}
                wordBreak
                animateSettings={{
                  willAnimate: true,
                  selfTrigger: true,
                }}
              >
                <Text field={title} />
              </Typography>
            )}
          </div>
          <div className={classes.bottomText}>
            {smallTitle && smallTitle.value && (
              <Typography
                variant="h5Bold"
                component="h2"
                className={classes.smallTitleText}
                gutterBottom={false}
                wordBreak
                animateSettings={{
                  willAnimate: true,
                  selfTrigger: true,
                }}
              >
                <Text field={smallTitle} />
              </Typography>
            )}
            {description && description.value && (
              <Typography
                variant="body1"
                component="p"
                className={classes.descriptionText}
                gutterBottom={false}
                wordBreak
                animateSettings={{
                  willAnimate: true,
                  selfTrigger: true,
                }}
              >
                <RichText
                  innerHTML={description}
                />
              </Typography>
            )}

            {CTA && CTA.value && CTA.value.text && (
              <ButtonMUI
                field={CTA}
                component="span"
                className={classes.bannerCTA}
                buttonType="whitePillContrast"
                size={'small'}
                hasBorder={false}
                onClick={analyticsEventCTA}
              >
                {CTA.value.text}
              </ButtonMUI>
            )}
          </div>
        </div>
      </div>
  )

}

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(BannerSupergraphic))
);
